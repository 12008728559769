import React from 'react'
import styled from 'styled-components';
import SbEditable from 'storyblok-react'

import Img from './layout/Image'

const sizes = {
	small: {
		y: false
	},
	medium: {
		x: 700,
		y: false
	}
}

const SBImage = ({ content }) => (
	<SbEditable content={content}>
		<Wrapper>
			{content.image.filename.endsWith('.svg') ? (
				<SVG src={content.image.filename} alt={content.image.alt} />
			) : (
					<Image
						className={`is-layout-${content.layout}`}
						image={content.image}
						contain
						sizes={sizes} />
				)}
		</Wrapper>
	</SbEditable>
)

export default SBImage

const Wrapper = styled.div`
    margin: 2rem 0;

	&:first-child {
		margin-top: 0;
	} 

	&:last-child {
		margin-bottom: 0;
	}
`;

const Image = styled(Img)`
  width: 100%;

  &.is-layout {
	  &-full {
		  margin: 0 -${({ theme }) => theme.borderOffset}px;
		  width: calc(100% + ${({ theme }) => theme.borderOffset * 2}px);
	  }
  }
`;

const SVG = styled.img`
	display: block;
	margin: 0 auto;
	width: 100%;
`;