import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import ArrowIcon from '../icons/Arrow'

const Link = ({ to, link, type, isWhite, children, className }) => {
	let ogClassName = className
	let url = to || ''
	let external = false

	if (link) {
		if (link.linktype === 'story') {
			// internal

			url = link.cached_url
			if (!url.startsWith('/')) url = `/${url}`
		} else {
			// external

			url = link.url
			external = true
		}
	}

	const LinkComponent = ({ children, className }) => external ? (
		<a href={url} rel="nofollow" className={className}>{children}</a>
	) : (
			<GatsbyLink to={url} className={className}>{children}</GatsbyLink>
		)

	return (
		<LinkComponent className={`button is-type-${type || 'minimal'} ${isWhite ? 'is-white' : ''} ${ogClassName || ''}`}>
			<ArrowIcon className='button__arrow' isWhite={isWhite || type === 'full'} isPrimary={!type || type === 'minimal'} />

			{children}
		</LinkComponent>
	)
}

export default Link