import React from 'react'
import SbEditable from 'storyblok-react'
import styled from 'styled-components';

import pattern from '../../images/bg-brushes-pattern.png'

const Section = ({ content, background, isShaded, isMaxWidth, isReadWidth, children }) => (
	<SbEditable content={content}>
		<Wrapper className={`is-bg-${(background || content.background) || 'pattern'} ${isShaded ? 'is-shaded' : ''}`}>
			<div className={`${isMaxWidth ? 'max-width' : ''} ${isReadWidth ? 'read-width' : ''}`}>
				{children}
			</div>
		</Wrapper>
	</SbEditable>
)

export default Section

const Wrapper = styled.section`
  padding: ${({ theme }) => theme.sectionSpace}px ${({ theme }) => theme.borderOffset}px;
  position: relative;

  @media only screen and (min-width: 769px) {
    padding: ${({ theme }) => theme.sectionSpaceLarge}px ${({ theme }) => theme.borderOffset}px;
  }

  &.is-bg-pattern {
	&::before {
		background: url(${pattern}) repeat 80px;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		opacity: .15;
		height: 100%;
		width: 100%;
		z-index: -1;
	}
  }

  &.is-bg-cyan {
	background: linear-gradient(160deg, ${({ theme }) => theme.color.primary} 0%, ${({ theme }) => theme.color.secondary} 100%);
	color: ${({ theme }) => theme.color.textWhite};
  }

  &.is-bg-gray {
	  background: ${({ theme }) => theme.color.gray};
  }

  &.is-shaded {
	&::before {
		background: linear-gradient(160deg, rgba(255, 255, 255, 0) 0%, rgba(43, 112, 128, 0.9) 100%);
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		opacity: 1;
		height: 100%;
		width: 100%;
		z-index: 0;

		@media only screen and (min-width: 769px) {
			background: linear-gradient(160deg, rgba(43, 112, 128, 0.2) 0%, rgba(43, 112, 128, 0.9) 100%);
		}
	}
  }
`;