import React from 'react'
import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

import Button from '../Button'
import Image from '../Image'

import ListIcon from '../../images/list.svg'
import ListIconWhite from '../../images/list-white.svg'

const RichText = ({ text, isWhite, isMultiCol }) => (
	<Wrapper className="read-width" isWhite={isWhite}>
		{render(text, {
			blokResolvers: {
				button: (props) => <Button content={props} isWhite={isWhite} isMultiCol={isMultiCol} />,
				image: (props) => <Image content={props} />
			}
		})}
	</Wrapper>
)

export default RichText

const Wrapper = styled.div`
  h3:not([class]) {
    margin: .9em 0 .5em 0;
  }

  h4:not([class]) {
    margin: .7em 0 .4em 0;
  }

  p:not([class]) {
    margin: 0 0 .8em 0;
  }

  ul:not([class]) {
    margin: 0 0 0 37px;

	list-style-image: url(${props => props.isWhite ? ListIconWhite : ListIcon});
  }
`;