import React from 'react'
import styled from 'styled-components';
import SbEditable from 'storyblok-react'

import Link from './layout/Link'

const Button = ({ content, isWhite, isMultiCol }) => (
	<SbEditable content={content}>
		<Wrapper className={isMultiCol && 'is-multicol'}>
			{content.quote && (
				<Quote>
					{content.quote}
					{content.quoteHighlight && <Highlight>{content.quoteHighlight}</Highlight>}
				</Quote>
			)}
			<LinkWrapper>
				<Link link={content.link} isWhite={isWhite} type={content.type}>
					{content.text}
				</Link>
			</LinkWrapper>
		</Wrapper>
	</SbEditable>
)

export default Button

const Wrapper = styled.div`
  margin: 1.5rem auto 0 auto;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
	${Wrapper}.is-multicol & {
		justify-content: left;
    }
  }
`;

const Quote = styled.blockquote`
  display: block;
  text-align: center;
  font-size: 1.3rem;
  margin: 0 0 1rem 0;
  width: 100%;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
  	${Wrapper}.is-multicol & {
		text-align: left;
    }
  }
`;

const Highlight = styled.p`
  display: block;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 2rem;
  margin: 1rem 0 0 0;
`;