import React from 'react'
import styled from 'styled-components';

const Arrow = ({ isWhite, isPrimary, className }) => (
	<svg className={className} viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<Path isWhite={isWhite} isPrimary={isPrimary} d="M2 10h27m0 0l-8-8m8 8l-8 9" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export default Arrow

const Path = styled.path`
	stroke: ${({ isWhite, isPrimary, theme }) => isWhite ? theme.color.textWhite : (isPrimary ? theme.color.primary : theme.color.textGray)};
`;